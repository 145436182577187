<template>
  <div class="portafolio">
    <Layout
      foto1="/img/_DSC0223.jpg"
      foto2="/img/DSC_0046.jpg"
      foto3="/img/_DSC0815.jpg"
      foto4="/img/DSC_0035.jpg"
      foto5="/img/DSC0418.jpg"
      foto6="/img/DSC_0764.jpg"
      foto7="/img/DSC_0528.jpg"
      foto8="/img/_DSC0017.jpg"
      foto9="/img/_DSC0289.jpg"
      foto10="/img/_DSC0109.jpg"
      foto11="/img/DSC_0342.jpg"
      foto12="/img/_DSC0073.jpg"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Layout from "@/components/Layout.vue";

export default {
  name: "PortafolioView",
  components: {
    Layout,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
