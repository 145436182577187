<template>
  <img
    :src="getImgUrl()"
    :alt="getImgAlt()"
    :class="getImgClass()"
    data-bs-toggle="modal"
    :data-bs-target="getModalTarget(true)"
  />

  <!-- Modal -->
  <div class="modal fade" :id="getModalTarget()" tabindex="-1">
    <div class="modal-dialog modal-imagen-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Foto</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <img :src="getImgUrl()" :alt="getImgAlt()" class="modal-imagen" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutFoto",
  props: {
    imgSrc: String,
    imgClass: String,
    imgAlt: String,
  },
  setup() {},
  methods: {
    getImgUrl: function () {
      return this.imgSrc;
    },
    getImgAlt: function () {
      return this.imgAlt;
    },
    getImgClass: function () {
      return "imagen " + this.imgClass;
    },
    getModalTarget: function (withHashtag) {
      return withHashtag ? "#" + this.getUniqueId() : this.getUniqueId();
    },
    getUniqueId: function () {
      return this.getImgUrl().replace(/[^a-zA-Z0-9]+/gi, "");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.imagen {
  float: left;
  padding-left: 1px;
  padding-bottom: 1px;
  max-width: 100%;
}

.imagen:hover {
  cursor: pointer;
}

.modal-imagen-dialog {
  max-width: 60%;
}

.modal-imagen {
  max-width: 100%;
}
</style>
